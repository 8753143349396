<template>
    <div class="index">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
            <router-view/>
        </div>

        <div class="index_body" v-show="!isQuiz">
            <div>
                <p class="index_body_info title" style="text-indent: 0;font-weight: bold; margin-bottom: 10px;">亲爱的会员朋友：</p>

                <p class="index_body_info">
                    今年1月举办的第一期手机拍摄类主题讲座好评如潮，大家意犹未尽！当然，手机拍摄的奥秘远不止于此。为了帮助大家学习、提升手机拍摄技能，<span
                    class="purple">4月18日</span>，SAP北分工会再次邀请到中国电影电视技术学会摄影摄像专业委员会副主任杨祥先生，进行<span
                    class="purple">线下专题讲座</span>，他将为大家分享更多面对镜头的技术与艺术，同时现场将安排互动拍摄剪辑，让会员参与共同完成主题图片和短视频作品，沉浸式感受面对镜头的基本技巧。
                </p>

                <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
                       style='border-collapse:collapse;border:none;width:90%;margin: 0 auto'>
                    <tr>
                        <td style='border:double #C45911 6.0pt'>
                            <ul style="list-style: square;padding-right: 15px">
                                <li>
                                    <b><span style='font-family:"微软雅黑",sans-serif;color:#C45911'>本期线下讲座内容核心：</span></b>
                                    <ul>
                                        <li><span
                                            style='font-family:"微软雅黑",sans-serif;color:#333333'>影视制作与新闻采访中镜头的拍摄规律；</span></li>
                                        <li><span
                                            style='font-family:"微软雅黑",sans-serif;color:#333333'>面对镜头的基本技巧；</span></li>
                                        <li><span
                                            style='font-family:"微软雅黑",sans-serif;color:black'>手机摄影摄像在形象宣传、工作展示中的应用；</span></li>
                                    </ul>
                                    <b><span style='font-family:"微软雅黑",sans-serif;color:black;font-size: 0.9em'>本次讲座将结合现实应用场景，邀请会员共同参与互动，使用手机共同完成主题图片和短视频作品的拍摄剪辑。</span></b>
                                </li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动时间：</span></b><b><span
                                    lang=EN-US style='font-family:"微软雅黑",sans-serif;color:#C45911'>4</span></b><b><span
                                    style='font-family:"微软雅黑",sans-serif;color:#C45911'>月<span lang=EN-US>18</span>日（周四）
    <span lang=EN-US>12:00-13:30</span></span></b></li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动地点：</span></b><span
                                    style='font-family:"微软雅黑",sans-serif;color:#333333'>公司10.15 & 10.16会议室</span></li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:#C45911'>本期讲座讲师：</span></b><br>
                                    <div style="display: inline-flex;width: 100%;flex-direction: row">
                                    <div style="width: 100px;padding-top: 1.5em">
                                        <img src="@/assets/image/speaker.jpg" width="91" style="display: flex;align-self: center">
                                    </div>
                                    <div style="flex: 1">
                                        <b><span style='font-family:"微软雅黑",sans-serif;font-size: 0.9em'>杨祥</span></b><br>
                                        <span style='font-family:"微软雅黑",sans-serif;font-size: 0.75em'>中国电影电视技术学会摄影摄像专业委员会副主任，中传国交共享影棚&I祥子影像工作室主理人，正高级工程师，中国传媒大学培训学院客座教授、硕士生校外导师，讲授影像技术、视听语言、拍摄技巧、短视频课程。
曾任北京奥运会秦皇岛赛区电视转播经理、秦皇岛电视台副台长等职务。近年来致力于新兴影像技术研究和影像语言的推广应用工作。受聘为多家权威媒体、党政机关进行线下线上培训，讲授影像技术视听语言和手机短视频课程。</span>
                                    </div>
                                    </div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>

            </div>

            <div class="index_body_button_box">
                <button class="index_button online" @click="regist()" :disabled="isDisabled">{{ btnText }}</button>
            </div>

        </div>


        <div v-if="questionLoaded">
            <div v-show="isQuiz">
                <Quiz></Quiz>
            </div>
        </div>
        <!--      <Error v-if="isShow" @close="isShow=false" :message="message"></Error>-->
    </div>
</template>

<script>
import Quiz from "../components/quiz"
import {mapActions, mapState} from "vuex";
import axios from "axios";

export default {
    name: "login",
    components: {
        // Quiz,
    },
    data() {
        return {
            isQuiz: false,
            questionLoaded: false,
            isClick: true,
            stats: {
                status: false,
                submitted: 0,
                submitted_limit: 0,
                waiting: 0,
                waiting_limit: 0
            },
        }
    },
    computed: {
        ...mapState({
            staff: state => state.staff
        }),
        isDisabled: function () {
            return !(this.stats.status === false && (this.stats.submitted < this.stats.submitted_limit || this.stats.waiting < this.stats.waiting_limit));
        },
        btnText: function () {
            if (this.stats.status) {
                return '已经报名'
            } else if (this.stats.submitted < this.stats.submitted_limit) {
                return '我要报名'
            } else if (this.stats.waiting < this.stats.waiting_limit) {
                return '报名已满，登记候补'
            } else {
                return '本场已满员'
            }
        },
        giftType: function () {
            return this.staff.giftType;
        }
    },
    watch: {
        giftType() {
            this.getStats(this.staff.giftType).then(result => {
                this.stats = result;
            })
        }
    },
    mounted() {
        // this.loadQuestion().then(() => {
        //     this.questionLoaded = true;
        // })
        this.getStats(this.staff.giftType).then(result => {
            this.stats = result;
        })
    },
    methods: {
        ...mapActions({
            'loadQuestion': 'initData',
            'getStats': 'getSubmitStats'
        }),
        regist() {
            // this.isQuiz = true

            console.log(this.isClick);
            if (this.isClick) {
                this.isClick = false
                axios.post('/submit', {score: 0, result: {}, staff: this.staff}).then(rsp => {
                    this.staff.msg = rsp.data.message;
                    this.$router.replace({name: 'result'})
                }).catch((e) => {
                    alert(e.response.data.message)
                    // this.isError = true
                    // this.message = e.response.data.message
                    this.isClick = true
                })
            }
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.index
    width 100%
    position relative

    .index_inner
        width 750px
        height 100%

        .index_banner
            width 100%

            .img
                width 100%;
                box-shadow 10px 10px 15px rgba(0, 0, 0, 0.2);

    .index_body
        width 750px
        //height 80vh
        position absolute
        top 300px
        display flex
        flex-direction column
        align-items center

        .purple
            color:#C45911 !important
            font-weight bold

        //justify-content space-around

        .index_body_info
            //text-indent 2em
            padding 0 55px
            margin 0 0 10px 0
            font-size 32px
            font-family "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
            font-weight 400
            color #000
            line-height 40px
            box-sizing border-box

            &.title
                font-size 36px

        .MsoTableGrid
            font-size 32px

            ul
                padding-left 1.5em

            li
                line-height 1.3em
                margin-bottom 5pt

        .index_body_img
            width 100%
            display flex
            flex-direction column
            align-items center

            img
                width 460px
                padding 10px 0

            span
                padding 10px 0

            .orspan
                font-size 40px
                font-weight bold
                //color red
                padding 20px 0

        .index_body_info_more
            padding-bottom 60px

        .index_body_button_box
            //width 100%
            padding 31px 75px

            .index_button
                buttonSet()
</style>
